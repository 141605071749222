import React, { useEffect } from 'react'

import { usePayInMethodContext } from '@guiker/payment-context'
import { Dropdown, Flex, P, PBold, PSmall, useFormContext, useTranslation } from '@guiker/react-framework'

import { getPayInMethodLabels } from '../../utils'
import { PayInMethodIcon } from '../PayInMethodIcon'

type Props = {
  showLabel?: boolean
  name?: string
  invoiceAmount?: number
}

export const MinimizedPayInMethodSelector: React.FC<Props> = ({ name, invoiceAmount, showLabel }) => {
  const { t } = useTranslation('common-payment')

  const { region, setSelectedById, payInMethods, selected } = usePayInMethodContext()
  const { setValue } = useFormContext()

  useEffect(() => {
    if (selected) {
      setValue(name, selected.id)
    }
  }, [selected])

  if (!payInMethods?.length) return <P mb={0}>{t('noPayInMethod')}</P>

  return (
    <Dropdown
      name={name}
      fullWidth
      onChange={(e) => setSelectedById(e.target.value)}
      defaultValue={selected?.id}
      label={showLabel && t('actions.selectPaymentMethod')}
      options={payInMethods?.map((payInMethod) => {
        const { id, disabled, type, brand, brandName, routingNumber, lastDigits, secondaryLabel } =
          getPayInMethodLabels({ payInMethod, region, t, amount: invoiceAmount })

        return {
          value: id,
          disabled,
          label: (
            <Flex gap={2} alignItems='flex-start'>
              <PayInMethodIcon type={type} brand={brand} routingNumber={routingNumber} />
              <Flex flexDirection='column'>
                <PBold mb={0}>
                  {brandName}
                  {'  '}
                  <P mb={0} component='span' color={60}>
                    {lastDigits}
                  </P>
                </PBold>
                <PSmall mb={0} color={30}>
                  {secondaryLabel}
                </PSmall>
              </Flex>
            </Flex>
          ),
        }
      })}
    />
  )
}
