import { PayoutMethodStatus } from '../entity'

export const PROCESSING_STATUS: PayoutMethodStatus[] = [
  PayoutMethodStatus.PENDING_SUBMISSION,
  PayoutMethodStatus.SUBMITTED,
]
export const NOTIFIED_STATUS: PayoutMethodStatus[] = [...PROCESSING_STATUS, PayoutMethodStatus.REJECTED]
export const IMMUTABLE_STATUS: PayoutMethodStatus[] = [
  PayoutMethodStatus.CHANGE_REQUESTED,
  PayoutMethodStatus.ENABLED,
  PayoutMethodStatus.REJECTED,
  PayoutMethodStatus.NEED_MORE_INFORMATION,
  PayoutMethodStatus.SUBMITTED,
]
