import React from 'react'

import { Scope } from '@guiker/base-entity'
import { AuthenticatedInvoiceContextProvider, useAuthenticatedInvoiceContext } from '@guiker/invoice-context'
import { usePaymentApiClient } from '@guiker/payment-context'
import { ScopeType } from '@guiker/payment-shared'
import { Outlet, useQuery } from '@guiker/react-framework'

import { InvoiceContextProvider } from './InvoiceContext'

type FetchArgs =
  | {
      scope: Scope<ScopeType>
    }
  | {
      invoiceId: string
    }

export type AuthInvoiceContextProviderProps = React.PropsWithChildren

const AuthInvoiceContextProvider: React.FC<AuthInvoiceContextProviderProps> = ({ children }) => {
  const apiClient = usePaymentApiClient()
  const { data: invoice, isFetching: isLoadingInvoice } = useAuthenticatedInvoiceContext()

  const { data: taxes, isLoading: isLoadingTaxes } = useQuery(['taxes'], () => apiClient.readAllTaxes())

  const { data: events, isLoading: isLoadingEvents } = useQuery(
    ['events', invoice?.id],
    () => apiClient.readAllInvoiceEventsByInvoiceId({ pathParams: { invoiceId: invoice?.id } }),
    { enabled: !!invoice?.id },
  )

  return (
    <InvoiceContextProvider
      taxes={taxes}
      invoice={invoice}
      isAdmin={false}
      events={events}
      isLoading={isLoadingTaxes || isLoadingInvoice || isLoadingEvents}
    >
      {children}
    </InvoiceContextProvider>
  )
}

export const AuthInvoiceNestedRouter: React.FC<FetchArgs> = (props) => {
  return (
    <AuthenticatedInvoiceContextProvider {...props}>
      <AuthInvoiceContextProvider>
        <Outlet />
      </AuthInvoiceContextProvider>
    </AuthenticatedInvoiceContextProvider>
  )
}
