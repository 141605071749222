export enum TaskType {
  /* Payment */
  INVOICE_FAILURE = 'INVOICE_FAILURE',
  REFRESH_PAY_IN_METHOD_VERIFICATION_TOKEN = 'REFRESH_PAY_IN_METHOD_VERIFICATION_TOKEN',

  /* RentPayment */
  RENT_PAYMENT_PLAN_CONTRIBUTION_RESPLIT = 'RENT_PAYMENT_PLAN_CONTRIBUTION_RESPLIT',
  CHANGE_RENT_PAY_IN_METHOD = 'CHANGE_RENT_PAY_IN_METHOD',

  /* Propsharing */
  COMPLETE_INVESTOR_PROFILE = 'COMPLETE_INVESTOR_PROFILE',
  REVIEW_INVESTOR_PROFILE = 'REVIEW_INVESTOR_PROFILE',
  COMPLETE_IPO_BUY_STOCK_ORDER = 'COMPLETE_IPO_BUY_STOCK_ORDER',
  CONFIRM_BUY_STOCK_ORDER_RISK = 'CONFIRM_BUY_STOCK_ORDER_RISK',
  CLAIM_PROMOTIONAL_STOCK_ORDER = 'CLAIM_PROMOTIONAL_STOCK_ORDER',

  /* Payout */
  REVIEW_PAYOUT_METHOD = 'REVIEW_PAYOUT_METHOD',
  PAYOUT_METHOD_FOLLOW_UP = 'PAYOUT_METHOD_FOLLOW_UP',
  CREATE_PAYOUT_METHOD = 'CREATE_PAYOUT_METHOD',
  RESUBMIT_PAYOUT_METHOD = 'RESUBMIT_PAYOUT_METHOD',
  COMPLETE_PAYOUT_METHOD = 'COMPLETE_PAYOUT_METHOD',

  /* Booking */
  REVIEW_ROOMMATE_INTRO = 'REVIEW_ROOMMATE_INTRO',
  RECONNECT_EFT = 'RECONNECT_EFT',
}

export const taskTypeRankings: {
  [key in TaskType]: number
} = {
  [TaskType.RECONNECT_EFT]: 1,
  [TaskType.COMPLETE_INVESTOR_PROFILE]: 1,
  [TaskType.REVIEW_PAYOUT_METHOD]: 1,
  [TaskType.REVIEW_INVESTOR_PROFILE]: 1,
  [TaskType.INVOICE_FAILURE]: 2,
  [TaskType.CHANGE_RENT_PAY_IN_METHOD]: 2,
  [TaskType.REFRESH_PAY_IN_METHOD_VERIFICATION_TOKEN]: 2,
  [TaskType.PAYOUT_METHOD_FOLLOW_UP]: 2,
  [TaskType.RESUBMIT_PAYOUT_METHOD]: 2,
  [TaskType.COMPLETE_PAYOUT_METHOD]: 2,
  [TaskType.RENT_PAYMENT_PLAN_CONTRIBUTION_RESPLIT]: 2,
  [TaskType.CREATE_PAYOUT_METHOD]: 2,
  [TaskType.COMPLETE_IPO_BUY_STOCK_ORDER]: 3,
  [TaskType.CONFIRM_BUY_STOCK_ORDER_RISK]: 4,
  [TaskType.REVIEW_ROOMMATE_INTRO]: 4,
  [TaskType.CLAIM_PROMOTIONAL_STOCK_ORDER]: 4,
}

export const CategoryTasks = [TaskType.REVIEW_INVESTOR_PROFILE, TaskType.REVIEW_PAYOUT_METHOD]
