import { routesBuilder } from '@guiker/shared-framework'

import { PayInMethod } from '../../../../entity'
import { paths } from '../paths'

const builder = routesBuilder<PayInMethod>()({ basePath: paths.payInMethod.all.withId(':payInMethodId') })

export const routes = {
  markVerificationTokenRefreshed: builder.buildPut({ path: '/mark-verification-token-refreshed' }),
  deleteOnePayInMethod: builder.buildDelete({ response: null as void }),
  readOnePayInMethod: builder.buildGet({}),
} as const
