import { config } from '../config'

export const webappRoutes = {
  root: {
    path: `/${config.domainName}`,
    documents: function () {
      return {
        path: `${this.path}/documents`,
      }
    },
    notifications: function () {
      return {
        path: `${this.path}/notification-preferences`,
      }
    },
    profile: function () {
      return {
        path: `${this.path}/profile`,
      }
    },
    security: function () {
      return {
        path: `${this.path}/security`,
      }
    },
    wallet: function () {
      return {
        path: `${this.path}/wallet`,
        fundingAccount: function () {
          return {
            path: `${this.path}/funding-account`,
          }
        },
        payments: function () {
          return {
            path: `${this.path}/payments`,
            paymentMethod: function (id: string) {
              return {
                path: `${this.path}/${id}`,
              }
            },
            history: function () {
              return {
                path: `${this.path}/history`,
              }
            },
            invoice: function () {
              return {
                path: `${this.path}/invoices`,
                byId: function (id: string) {
                  return {
                    path: `${this.path}/${id}`,
                  }
                },
              }
            },
          }
        },
        payoutPreferences: function () {
          return {
            path: `${this.path}/payout-preferences`,
            payoutMethod: function (id: string) {
              return {
                path: `${this.path}/payout-method/${id}`,
              }
            },
          }
        },
      }
    },
  },
}
