import React from 'react'

import { Transaction } from '@guiker/payment-shared'
import { MessagesBubbleIcon, Note, P, PBold, useT } from '@guiker/react-framework'
import { last } from '@guiker/shared-framework'

import { useInvoiceContext } from '../../../hooks'
import { detailsByType } from '../../../utils'

const printErrorDetails = (errorDetails: Transaction['details']) => {
  const rawError = errorDetails.error?.raw

  if (typeof rawError === 'object') return JSON.stringify(rawError)
  else if (typeof rawError === 'string') return rawError

  return errorDetails.note || errorDetails.error?.code || '-'
}

export const FailReason: React.FC = () => {
  const { tShared } = useT({ domain: 'payment' })
  const { invoice, invoiceState, isAdmin } = useInvoiceContext()
  const { transactions } = invoice

  const failedTransactions = transactions?.filter(({ status, intent }) => {
    return status === 'FAILED' && (intent === 'CHARGE' || intent === 'VERIFICATION')
  })
  const { details: errorDetails, payInMethod } = { ...last(failedTransactions) }

  if (!invoiceState.isFailed || !errorDetails) return <></>

  const errorMessage = isAdmin
    ? printErrorDetails(errorDetails)
    : errorDetails.note || (tShared(`failedNote.${errorDetails.error?.code}`) ?? '-')

  return (
    <Note
      type={errorMessage.length > 400 ? 'collapsible' : 'none'}
      icon={<MessagesBubbleIcon />}
      title={tShared(`failedNote.header`)}
      statusColor='info'
      mb={3}
    >
      <P fontStyle='italic' mb={1}>
        {errorMessage}
      </P>
      <PBold mb={0}>{`${tShared(`failedNote.paymentMethod`)} ${detailsByType(payInMethod, tShared)}`}</PBold>
    </Note>
  )
}
