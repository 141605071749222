import React from 'react'

import { AnyColor, Flex, P, PBold } from '@guiker/react-framework'

type InvoiceItemProps = {
  label: string
  value: string
  color?: AnyColor
  bold?: boolean
}

export const InvoiceItem: React.FC<InvoiceItemProps> = ({ label, value, color, bold = false }) => {
  const Typo = bold ? PBold : P

  return (
    <Flex fullWidth justifyContent='space-between'>
      <Typo color={color} mb={1} textAlign='left'>
        {label}
      </Typo>
      <Typo color={color} mb={1} textAlign='right'>
        {value}
      </Typo>
    </Flex>
  )
}
