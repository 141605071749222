import { config } from '../config'

export const webappRoutes = {
  invoice: {
    path: `/${config.domainName}/invoice`,
    byId: function (invoiceId: string) {
      return {
        path: `${this.path}/${invoiceId}/`,
      }
    },
  },
  payment: {
    path: `/${config.domainName}`,
    get payInMethod() {
      return {
        path: `${this.path}/pay-in-method`,
        byId: function (payInMethodId: string) {
          return {
            path: `${this.path}/${payInMethodId}`,
            get verification() {
              return {
                path: `${this.path}/verification`,
              }
            },
          }
        },
      }
    },
  },
}
