import { Invoice, invoiceTypeChecker } from '@guiker/payment-shared'

export const getInvoiceReason = ({ invoice }: { invoice: Invoice }) => {
  let reason
  if (invoiceTypeChecker.scope.isTenantInstalment(invoice)) {
    reason = invoice.info?.label ?? invoice.metadata.description

    if (reason.indexOf('/') !== -1) {
      const [firstLine, secondLine] = reason.split('/')
      const [city] = secondLine?.split(',') || [secondLine]
      reason = `${firstLine}, ${city}`
    }
  } else if (invoiceTypeChecker.scope.isBuyStockOrder(invoice)) {
    const item = invoice.items[0]
    reason = [item.label, item.description].join(' - ')
  } else if (invoiceTypeChecker.scope.isFundingAccountDeposit(invoice)) {
    reason = invoice.items[0].label
  } else if (invoiceTypeChecker.scope.isNone(invoice)) {
    const { reason: metadataReason } = { ...invoice.metadata }
    reason = metadataReason
  }
  return reason
}
