import { getBankByRouting, money } from '@guiker/money'
import {
  CREDIT_CARD_TRANSACTION_RATE,
  getCurrencyFromSupportedCountry,
  PayInMethod,
  payInMethodInfoBuilder,
  SupportedCountries,
  typeChecker,
} from '@guiker/payment-shared'

import { TFunction, useTranslation } from '../i18n'

export const useGetPayInMethodLabel = () => {
  const { t } = useTranslation('common-payment')

  return (payInMethod: PayInMethod, options: { hideCCBrand?: boolean } = {}): string => {
    if (!payInMethod) return ''

    const { getLastDigits } = payInMethodInfoBuilder(payInMethod)
    const lastDigits = t('endingWith', { lastDigits: getLastDigits() })
    const currency = getCurrencyFromSupportedCountry(payInMethod.region)

    if (typeChecker.isCreditCard(payInMethod)) {
      return options.hideCCBrand ? lastDigits : `${t(`creditCard.brands.${payInMethod.details.brand}`)} ${lastDigits}`
    } else if (typeChecker.isEFT(payInMethod)) {
      const eftBank = getBankByRouting(payInMethod.details?.institutionId)
      return `${eftBank?.shortName ?? t('bank')} ${lastDigits}`
    } else if (typeChecker.isACH(payInMethod)) {
      const achBank = getBankByRouting(payInMethod.details?.routingNumber)
      return `${achBank?.shortName ?? payInMethod.details?.bankName ?? t('bank')} ${lastDigits}`
    } else if (typeChecker.isPAD(payInMethod)) {
      const padBank = getBankByRouting(payInMethod.details?.institutionId)
      return `${padBank?.shortName ?? payInMethod.details?.bankName ?? t('bank')} ${lastDigits}`
    } else if (typeChecker.isFundingAccount(payInMethod)) {
      const balance = money.fromAmount(payInMethod.details?.balance, currency).toString()
      return `${t('fundingAccount.label', { balance })}`
    } else if (typeChecker.isOffline(payInMethod)) {
      return `${t('offline')} ${payInMethod.details?.bankName ?? ''}`
    } else {
      return t('offline')
    }
  }
}

export const getPayInMethodLabels = ({
  payInMethod,
  region,
  t,
  amount,
}: {
  payInMethod: PayInMethod
  region: SupportedCountries
  t: TFunction
  amount?: number
}) => {
  const { id, type, details = {} } = { ...payInMethod }

  const brand = details ? details['brand'] : undefined
  const { getLastDigits } = payInMethodInfoBuilder(payInMethod)
  const lastDigits = t('endingWith', { lastDigits: getLastDigits() })

  const brandName =
    typeChecker.isACH(payInMethod) || typeChecker.isPAD(payInMethod)
      ? payInMethod.details?.bankName
      : typeChecker.isEFT(payInMethod)
      ? t('directDebit.EFT.label')
      : typeChecker.isFundingAccount(payInMethod)
      ? t('fundingAccount.label')
      : typeChecker.isCreditCard(payInMethod)
      ? t(`creditCard.brands.${brand}`)
      : t('offline')

  const secondaryLabel = typeChecker.isCreditCard(payInMethod)
    ? t('processingFee', {
        processingFeePercentage: `${CREDIT_CARD_TRANSACTION_RATE * 100}%`,
      })
    : typeChecker.isFundingAccount(payInMethod) && payInMethod.details.balance != null
    ? `${money.fromAmount(payInMethod.details.balance, getCurrencyFromSupportedCountry(region))}`
    : null

  const routingNumber = details['routingNumber'] ?? details['institutionId']

  const disabled = typeChecker.isFundingAccount(payInMethod) && amount != null && payInMethod.details.balance < amount

  return {
    brand,
    brandName,
    disabled,
    id,
    lastDigits,
    routingNumber,
    secondaryLabel,
    type,
  }
}
