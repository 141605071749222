import React, { useEffect, useState } from 'react'

import { SupportedCountries, SupportedStates } from '@guiker/payment-shared'
import {
  DatePicker,
  Dropdown,
  getLocalizedCountry,
  getLocalizedState,
  Grid,
  makeStyles,
  TextField,
  theme,
  useTranslation,
  useWatch,
} from '@guiker/react-framework'

const useStyles = makeStyles({
  gridContainer: {
    marginLeft: theme.spacing(-1),
    marginRight: theme.spacing(-1),
    width: 'initial',
    '& > div': {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  streetNumber: {
    marginRight: theme.spacing(2),
  },
})

type Props = {
  country: (typeof SupportedCountries)[keyof typeof SupportedCountries]
}

const ProfileForm: React.FC<Props> = ({ country: defaultCountry }) => {
  const tPrefix = 'common-payment:directDebit'
  const [countryStates, setCountryStates] = useState<{ label: string; value: string }[]>([])

  const { t } = useTranslation(['common, common-payment'])
  const classes = useStyles()
  const country = useWatch({ name: 'profile.billingAddress.country', defaultValue: defaultCountry })

  useEffect(() => {
    if (SupportedStates[country]) {
      setCountryStates(
        Object.values(SupportedStates[country]).map((state: string) => ({
          label: getLocalizedState(t, { countryCode: country, stateCode: state }),
          value: state,
        })),
      )
    }
  }, [country])

  return (
    <>
      <Grid container className={classes.gridContainer}>
        <Grid item sm={6} xs={12}>
          <TextField maxWidth='100%' label={t(`${tPrefix}.accountHolderName`)} name='profile.accountHolderName' />
        </Grid>
        <Grid item sm={6} xs={12}>
          <DatePicker
            maxWidth='100%'
            label={t(`${tPrefix}.dateOfBirth`)}
            name='profile.birthDate'
            format='yyyy-MM-dd'
            fullWidth
            autoOk
            disableFuture
          />
        </Grid>
      </Grid>
      <Grid container className={classes.gridContainer}>
        <Grid item sm={6} xs={12}>
          <TextField maxWidth='100%' label={t('common:address.suiteNumber')} name='profile.billingAddress.street2' />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            className={classes.streetNumber}
            maxWidth='100%'
            label={t('common:address.streetNumber')}
            name='profile.billingAddress.streetNumber'
          />
        </Grid>
      </Grid>
      <Grid container className={classes.gridContainer}>
        <Grid item sm={12}>
          <TextField maxWidth='100%' label={t('common:address.streetName')} name='profile.billingAddress.streetName' />
        </Grid>
      </Grid>
      <Grid container className={classes.gridContainer}>
        <Grid item sm={6} xs={12}>
          <TextField maxWidth='100%' label={t('common:address.city')} name='profile.billingAddress.city' />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Dropdown
            maxWidth='100%'
            name='profile.billingAddress.state'
            label={t('common:address.state')}
            defaultValue={countryStates[0]?.value}
            options={countryStates}
          />
        </Grid>
      </Grid>
      <Grid container className={classes.gridContainer}>
        <Grid item sm={6} xs={12}>
          <Dropdown
            maxWidth='100%'
            name='profile.billingAddress.country'
            label={t('common:address.country')}
            defaultValue={defaultCountry}
            options={Object.values(SupportedCountries).map((country: string) => ({
              label: getLocalizedCountry(t, { countryCode: country }),
              value: country,
            }))}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField maxWidth='100%' label={t('common:address.postalCode')} name='profile.billingAddress.zip' />
        </Grid>
      </Grid>
    </>
  )
}

export { ProfileForm }
