import React, { useEffect, useState } from 'react'

import { useAuthenticationContext } from '@guiker/authentication-context'
import { CountryCode } from '@guiker/payment-verification-shared'
import { ButtonWithLoader, Features, SecondaryButton, useFeatureFlag, useMutation } from '@guiker/react-framework'
import { useSentryContext } from '@guiker/sentry-context'
import { getUserFullName } from '@guiker/shared-framework'
import { StripeProvider, useAuthenticatedPaymentVerificationApiClient, useStripe } from '@guiker/stripe-context'

import { PlaidLink } from '../PlaidLink'

type StripeLinkProps = {
  label: string
  onSelect: (args: { publicToken: string; accountId: string }) => void
  countryCode: CountryCode
}

const sentryMessage = 'Stripe::ConfirmAcssDebitSetup'

const Content: React.FC<StripeLinkProps> = ({ label, countryCode, onSelect }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [accountId, setAccountId] = useState<string>()
  const [acssSetupSecret, setAcssSetupSecret] = useState<string>()
  const { captureMessage } = useSentryContext()
  const { user } = useAuthenticationContext()
  const [state, setState] = useState<{
    name: string
    routingNumber: string
    accountNumber: string
    mask: string
  }>()

  const isPlaidWithStripePADEnabled = useFeatureFlag().isEnabled(Features.PlaidWithStripePAD)
  const apiClient = useAuthenticatedPaymentVerificationApiClient()
  const stripe = useStripe()

  const { mutate: generateAcssSetupSecret, isLoading: isGenerating } = useMutation(
    () => apiClient.createStripeLinkToken({ payload: { countryCode } }),
    {
      onSuccess: (res) => {
        setIsLoading(true)
        setAcssSetupSecret(res)
      },
    },
  )

  useEffect(() => {
    if (acssSetupSecret) {
      captureMessage({ message: sentryMessage, messageInfo: { acssSetupStarted: 'loaded' } })
      const [institution, transit] = state.routingNumber?.split('-')
      stripe.client
        .confirmAcssDebitSetup(acssSetupSecret, {
          payment_method: {
            billing_details: {
              name: isPlaidWithStripePADEnabled ? state.name : getUserFullName(user),
              email: user.emailAddress,
            },
            ...(isPlaidWithStripePADEnabled
              ? {
                  acss_debit: {
                    institution_number: institution,
                    transit_number: transit,
                    account_number: state.accountNumber,
                  },
                }
              : undefined),
          },
        })
        .then(({ error, setupIntent }) => {
          if (error) {
            captureMessage({ message: sentryMessage, messageInfo: error as Record<string, any> })
            return
          } else if (setupIntent.status !== 'succeeded') {
            captureMessage({ message: sentryMessage, messageInfo: setupIntent as Record<string, any> })
            return
          }
          /**
           * @todo with manual acss_debit entry, we might trigger micro-deposit flow
           * check setupIntent.status === 'requires_action'
           * check next_action
           * before setting setAccountId, open custom mandate agreement
           **/
          setAccountId(setupIntent.id)
        })
        .catch((error) => {
          captureMessage({ message: sentryMessage, messageInfo: error })
        })
        .finally(() => setIsLoading(false))
    }
  }, [acssSetupSecret])

  useEffect(() => {
    accountId && onSelect({ accountId, publicToken: acssSetupSecret })
  }, [accountId, acssSetupSecret])

  if (isPlaidWithStripePADEnabled) {
    return (
      <PlaidLink
        label={label}
        countryCode={countryCode}
        isLoading={isLoading || isGenerating}
        onSelect={({ auth }) => {
          setState(auth)
          generateAcssSetupSecret()
        }}
      />
    )
  }

  return (
    <ButtonWithLoader
      type='button'
      variant='outlined'
      isLoading={isGenerating || isLoading}
      buttonComponent={SecondaryButton}
      onClick={() => generateAcssSetupSecret()}
    >
      {label}
    </ButtonWithLoader>
  )
}

export const StripeLink: React.FC<StripeLinkProps> = (props) => {
  return (
    <StripeProvider region={props.countryCode}>
      <Content {...props} />
    </StripeProvider>
  )
}
