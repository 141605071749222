import { ContextUser } from '@guiker/authentication-context'
import { Invoice, PayoutMethod, PayoutProviderName } from '@guiker/payment-shared'
import { mainPathBuilder } from '@guiker/payout-shared'
import { LinkProps, useT } from '@guiker/react-framework'
import { last } from '@guiker/shared-framework'

export type AdminAccessLink = {
  providerLink: { payment?: LinkProps & { value: string }; payoutMethod?: LinkProps & { value: string } }
  payoutMethodLink: {
    payoutMethod?: LinkProps
    receiver?: LinkProps
  }
}

export const findPayoutMethodLink = ({
  invoice,
  payoutMethod,
  user,
}: {
  invoice: Invoice
  user: ContextUser
  payoutMethod?: PayoutMethod
}): LinkProps & { value: string } => {
  if (!payoutMethod || !invoice || invoice?.receiver?.userId !== user.id || payoutMethod?.userId !== user.id) return
  const { tShared } = useT({ domain: 'payment' })

  return {
    to: mainPathBuilder.root.byId(payoutMethod.id).path,
    target: '_blank',
    value: tShared('info.viewPayoutMethod'),
  }
}

export const findAdminAccessLink = ({
  invoice,
  payoutMethod,
}: {
  invoice: Invoice
  payoutMethod: PayoutMethod
}): AdminAccessLink => {
  if (!invoice) return
  const { tShared } = useT({ domain: 'payment' })
  const receiverUserId = invoice?.receiver?.userId
  const lastTransaction = last(invoice?.transactions)
  const provider = lastTransaction?.payInMethod?.provider.name
  const isPaymentProviderStripe = provider === 'STRIPE' && !!lastTransaction?.details?.uid
  const stripePayoutProvider = payoutMethod?.providers?.find((p) => p.name === PayoutProviderName.STRIPE)
  return {
    payoutMethodLink: {
      payoutMethod: !!payoutMethod ? { to: `/payout/${payoutMethod.id}`, prefixWithLocale: false } : undefined,
      receiver: !!receiverUserId ? { to: `/user/${receiverUserId}`, prefixWithLocale: false } : undefined,
    },
    providerLink: {
      payment: isPaymentProviderStripe
        ? {
            to: `https://dashboard.stripe.com/payments/${lastTransaction?.details?.uid}`,
            target: '_blank',
            isExternalLink: true,
            value: tShared('info.provider.payment', { provider: 'Stripe' }),
          }
        : undefined,
      payoutMethod: stripePayoutProvider
        ? {
            to: `https://dashboard.stripe.com/connect/accounts/${stripePayoutProvider.accountId}`,
            target: '_blank',
            isExternalLink: true,
            value: tShared(`info.provider.payout`, { provider: 'Stripe' }),
          }
        : undefined,
    },
  }
}
