import { webappRoutes as accountWebappRoutes } from '@guiker/account-shared'
import { mainPathBuilder as bookingPathBuilder } from '@guiker/booking-shared'
import { mainPathBuilder as conversationPathBuilder } from '@guiker/conversation-shared'
import {
  DirectDebitACHPayInMethod,
  Invoice,
  ScopeType,
  webappRoutes as paymentWebappRoutes,
} from '@guiker/payment-shared'
import { mainPathBuilder as payoutWebappRoutes, PayoutMethod } from '@guiker/payout-shared'
import { InvestorProfile, StockOrder, webappRoutes } from '@guiker/propsharing-shared'
import { TFunction } from '@guiker/react-framework'
import {
  TenantInstalmentWithRentPaymentsPlan,
  webappRoutes as rentPaymentWebappRoutes,
} from '@guiker/rent-payment-shared'
import { getUserFullName, optionalConcat } from '@guiker/shared-framework'
import { Task, TaskType } from '@guiker/task-shared'

export type TaskItem = {
  date: string
  path: string
  header: string
  body: string
  cta: string
  category?: string
}

export const taskMapping = (task: Task, t: TFunction, count = 0): TaskItem => {
  const tPrefix = `common-task:tasks.${task?.type}`
  const base = {
    date: task.createdAt,
    header: t(`${tPrefix}.header`),
    body: t(`${tPrefix}.body`),
    cta: t(`${tPrefix}.cta`),
    path: '',
  }

  const invoiceFailureMapping = (invoice: Invoice) => {
    switch (invoice.scope?.type) {
      case ScopeType.BUY_STOCK_ORDER:
        return t(`${tPrefix}.buyStockOrder.header`, { stock: invoice.info?.label })
      case ScopeType.TENANT_INSTALMENT:
        return t(`${tPrefix}.tenantInstalment.header`, { instalment: invoice.info?.label })
      default:
        return t(`${tPrefix}.header`, { invoice: (task.data as Invoice)?.id })
    }
  }

  switch (task?.type) {
    case TaskType.COMPLETE_INVESTOR_PROFILE:
      return {
        ...base,
        path: webappRoutes.investorProfile.path,
      }
    case TaskType.COMPLETE_IPO_BUY_STOCK_ORDER:
      return {
        ...base,
        header: t(`${tPrefix}.header`, { stock: (task.data as StockOrder)?.stock?.tickerSymbol }),
        path: webappRoutes.stockOrder.byId(task.entityId).checkout.path,
      }
    case TaskType.INVOICE_FAILURE:
      return {
        ...base,
        header: invoiceFailureMapping(task.data as Invoice),
        path: paymentWebappRoutes.invoice.byId(task.entityId).path,
      }
    case TaskType.REFRESH_PAY_IN_METHOD_VERIFICATION_TOKEN:
      return {
        ...base,
        body: t(`${tPrefix}.body`, {
          accountNumber: (task.data as DirectDebitACHPayInMethod)?.details.accountNumber,
        }),
        path: paymentWebappRoutes.payment.payInMethod.byId(task.entityId).verification.path,
      }
    case TaskType.CHANGE_RENT_PAY_IN_METHOD:
      const { scope } = { ...(task.data as TenantInstalmentWithRentPaymentsPlan).rentPaymentsPlan }
      return {
        ...base,
        path: scope
          ? rentPaymentWebappRoutes.scope(scope).rentPayments.path
          : conversationPathBuilder.root.messages.path(),
      }
    case TaskType.CLAIM_PROMOTIONAL_STOCK_ORDER:
      const { stock, share } = { ...task.data } as StockOrder
      return {
        ...base,
        header: t(`${tPrefix}.header`, { stock: stock?.tickerSymbol }),
        body: t(`${tPrefix}.body`, { stock: stock?.tickerSymbol, units: share?.quantity }),
        path: webappRoutes.stockOrder.byId(task.entityId).claimPromotion.path,
      }
    case TaskType.CONFIRM_BUY_STOCK_ORDER_RISK:
      return {
        ...base,
        header: t(`${tPrefix}.header`, { stock: (task.data as StockOrder)?.stock?.tickerSymbol }),
        path: webappRoutes.stockOrder.byId(task.entityId).confirmRisk.path,
      }
    case TaskType.REVIEW_INVESTOR_PROFILE:
      return {
        ...base,
        category: t(`${tPrefix}.category`, { count }),
        header: t(`${tPrefix}.header`, { name: getUserFullName((task.data as InvestorProfile).personal.legalName) }),
        path: webappRoutes.investorProfile.byId(task.entityId).review.path,
      }
    case TaskType.CREATE_PAYOUT_METHOD:
      return {
        ...base,
        header: t(`${tPrefix}.header`, { name }),
        path: payoutWebappRoutes.root.create.path,
      }
    case TaskType.RESUBMIT_PAYOUT_METHOD:
      return {
        ...base,
        path: accountWebappRoutes.root.wallet().payoutPreferences().path,
      }
    case TaskType.COMPLETE_PAYOUT_METHOD:
      return {
        ...base,
        path: accountWebappRoutes.root.wallet().payoutPreferences().path,
      }
    case TaskType.REVIEW_ROOMMATE_INTRO:
      return {
        ...base,
        path: bookingPathBuilder.root.byId(task.data?.id).roommateIntroReview.path,
      }
    case TaskType.RECONNECT_EFT:
      return {
        ...base,
        path: bookingPathBuilder.root.byId(task.data?.id).payment.reconnect.path,
      }
    case TaskType.REVIEW_PAYOUT_METHOD:
      const accountHolder = optionalConcat(
        [(task.data as PayoutMethod).accountHolder.firstName, (task.data as PayoutMethod).accountHolder.lastName],
        ' ',
      )
      return {
        ...base,
        category: t(`${tPrefix}.category`, { count }),
        header: t(`${tPrefix}.header`, { name: accountHolder }),
        path: `payout/${task.data.id}`,
      }
    case TaskType.PAYOUT_METHOD_FOLLOW_UP:
      const accountHolderName = optionalConcat(
        [(task.data as PayoutMethod).accountHolder.firstName, (task.data as PayoutMethod).accountHolder.lastName],
        ' ',
      )
      return {
        ...base,
        header: t(`${tPrefix}.header`, { name: accountHolderName }),
        path: `payout/${task.data.id}`,
      }
    default:
      return {
        date: '',
        header: '',
        body: '',
        path: '',
        cta: '',
      }
  }
}
