import React from 'react'

import { Invoice, Scopeless, ScopeType } from '@guiker/payment-shared'
import { ActivityLabel, Source, useT } from '@guiker/react-framework'
import { DateTime, Event, formatDateTime, selectFromMap } from '@guiker/shared-framework'

export const transformEventToActivityComponent = ({ event }: { event: Event<Invoice> }) => {
  const { emittedAt, type, data } = event
  const timestamp = formatDateTime(emittedAt)
  const { tMain } = useT({ domain: 'payment', screenName: 'screens.activityLog' })
  const reason = !data.scope
    ? (data as Invoice<Scopeless>).metadata.reason
    : selectFromMap<string>({
        [ScopeType.BUY_STOCK_ORDER]: tMain('buyStockOrder'),
        [ScopeType.FUNDING_ACCOUNT_DEPOSIT]: tMain('fundingAccountDeposit'),
        [ScopeType.TENANT_INSTALMENT]: `${tMain('rentPayment')} ${DateTime.fromISO(data.createdAt).month}/${
          DateTime.fromISO(data.createdAt).year
        }`,
        default: data.items[0]?.label,
      })(data.scope.type)

  let copy
  switch (type) {
    case 'CREATED':
      return {
        content: <Source content={timestamp} />,
        label: <ActivityLabel content={tMain('CREATED', { reason })} />,
      }
    case 'CANCELLED':
      copy = tMain('CANCELLED')
      return {
        content: <Source content={timestamp} />,
        label: <ActivityLabel content={copy} />,
      }
    case 'CHARGE_PENDING':
      copy = tMain('CHARGE_PENDING')
      return {
        content: <Source content={timestamp} />,
        label: <ActivityLabel content={copy} />,
      }
    case 'CHARGE_SUCCEED':
      copy = tMain('CHARGE_SUCCEED')
      return {
        content: <Source content={timestamp} />,
        label: <ActivityLabel content={copy} />,
      }
    case 'CHARGE_FAILED':
      copy = tMain('CHARGE_FAILED')
      return {
        content: <Source content={timestamp} />,
        label: <ActivityLabel content={copy} />,
      }
    case 'OFFLINE_CHARGE_SUCCEED':
      copy = tMain('OFFLINE_CHARGE_SUCCEED')
      return {
        content: <Source content={timestamp} />,
        label: <ActivityLabel content={copy} />,
      }
    case 'OFFLINE_CHARGE_FAILED':
      copy = tMain('OFFLINE_CHARGE_FAILED')
      return {
        content: <Source content={timestamp} />,
        label: <ActivityLabel content={copy} />,
      }
    case 'OFFLINE_CHARGE_UNDONE':
      copy = tMain('OFFLINE_CHARGE_UNDONE')
      return {
        content: <Source content={timestamp} />,
        label: <ActivityLabel content={copy} />,
      }
    case 'REFUND_PENDING':
      copy = tMain('REFUND_PENDING')
      return {
        content: <Source content={timestamp} />,
        label: <ActivityLabel content={copy} />,
      }
    case 'REFUND_FAILED':
      copy = tMain('REFUND_FAILED')
      return {
        content: <Source content={timestamp} />,
        label: <ActivityLabel content={copy} />,
      }
    case 'REFUND_SUCCEED':
      copy = tMain('REFUND_SUCCEED')
      return {
        content: <Source content={timestamp} />,
        label: <ActivityLabel content={copy} />,
      }
    case 'IMMEDIATE_CHARGEABLE_CREATED':
      copy = tMain('IMMEDIATE_CHARGEABLE_CREATED', { reason })
      return {
        content: <Source content={timestamp} />,
        label: <ActivityLabel content={copy} />,
      }
    case 'VERIFICATION_REQUESTED':
      copy = tMain('VERIFICATION_REQUESTED')
      return {
        content: <Source content={timestamp} />,
        label: <ActivityLabel content={copy} />,
      }
    case 'VERIFICATION_FAILED':
      copy = tMain('VERIFICATION_FAILED')
      return {
        content: <Source content={timestamp} />,
        label: <ActivityLabel content={copy} />,
      }
    case 'VERIFICATION_SUCCEED':
      copy = tMain('VERIFICATION_SUCCEED')
      return {
        content: <Source content={timestamp} />,
        label: <ActivityLabel content={copy} />,
      }
    case 'FUNDING_ACCOUNT_CHARGE_PENDING':
      copy = tMain('FUNDING_ACCOUNT_CHARGE_PENDING')
      return {
        content: <Source content={timestamp} />,
        label: <ActivityLabel content={copy} />,
      }
    case 'FUNDING_ACCOUNT_CHARGE_SUCCEED':
      copy = tMain('FUNDING_ACCOUNT_CHARGE_SUCCEED')
      return {
        content: <Source content={timestamp} />,
        label: <ActivityLabel content={copy} />,
      }
    case 'FUNDING_ACCOUNT_CHARGE_FAILED':
      copy = tMain('FUNDING_ACCOUNT_CHARGE_FAILED')
      return {
        content: <Source content={timestamp} />,
        label: <ActivityLabel content={copy} />,
      }
    case 'STOCK_ORDER_INVOICE_REIMBURSED':
      copy = tMain('STOCK_ORDER_INVOICE_REIMBURSED')
      return {
        content: <Source content={timestamp} />,
        label: <ActivityLabel content={copy} />,
      }
    default: {
      return
    }
  }
}
