import React, { useEffect, useState } from 'react'

import { ButtonWithLoader, Flex, RadioGroup, SecondaryButton, Spinner } from '@guiker/components-library'
import { useMutation } from '@guiker/react-query'
import { useSentryContext } from '@guiker/sentry-context'
import {
  Account,
  CountryCode,
  useAuthenticatedPaymentVerificationApiClient,
  usePlaidLink,
} from '@guiker/use-plaid-link'

type PlaidLinkProps = {
  label: string
  onSelect: (args: {
    publicToken: string
    accountId: string
    auth?: {
      name: string
      routingNumber: string
      accountNumber: string
      mask: string
    }
  }) => void
  countryCode: CountryCode
  plaidAccessToken?: string
  isLoading?: boolean
}

type PlaidLinkContentProps = PlaidLinkProps & {
  isForUpdate?: boolean
  linkToken: string
  fetchLinkToken: () => Promise<void>
}

type PlaidLinkState = {
  accounts: Account[]
  selectedAccount?: Account
  publicToken?: string
}

const Content: React.FC<PlaidLinkContentProps> = (props) => {
  const { label, onSelect, linkToken, fetchLinkToken, isForUpdate, countryCode, isLoading } = props
  const [state, setState] = useState<PlaidLinkState>({ accounts: [] })
  const { captureMessage } = useSentryContext()
  const apiClient = useAuthenticatedPaymentVerificationApiClient()

  const { mutateAsync: getBankAccount, isLoading: isLoadingBankAccount } = useMutation(
    (args: { publicToken: string; accountId: string }) => {
      const { publicToken, accountId } = args
      return apiClient.readPlaidBankAccountDetails({ payload: { publicToken, accountId, countryCode } })
    },
  )

  const { open, ready } = usePlaidLink({
    linkToken,
    onSuccess: (publicToken, accounts) => {
      const accountId = accounts[0]?.id
      getBankAccount({ publicToken, accountId }).then((res) => {
        const { identity, mask, routingNumber, accountNumber } = res
        onSelect({ publicToken, accountId, auth: { name: identity.name, mask, routingNumber, accountNumber } })
        setState({ ...state, publicToken, accounts })
      })
    },
    onEvent: (eventName, event) => captureMessage({ message: eventName, messageInfo: event }),
    onError: (errorCode, errorMessage) => {
      if (errorCode === 'INVALID_LINK_TOKEN') {
        fetchLinkToken()
      } else {
        throw new Error(`${errorCode}: ${errorMessage}`)
      }
    },
  })

  const handleOnChange = (_event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    const selectedAccount = state.accounts.find((acc) => acc.id === value)
    setState({ ...state, selectedAccount })
  }

  useEffect(() => {
    if (state.publicToken && state.selectedAccount) {
      onSelect({
        publicToken: state.publicToken,
        accountId: state.selectedAccount.id,
      })
    }
  }, [state.publicToken, state.accounts, state.selectedAccount])

  useEffect(
    () => () => {
      document.body.style.overflow = 'visible'
    },
    [],
  )

  if (!ready) {
    return <Spinner />
  }

  return (
    <Flex flexDirection='column' gap={2}>
      {state.publicToken && state.accounts.length > 0 && !isForUpdate && (
        <RadioGroup
          name='plaid-link-accounts'
          onChange={handleOnChange}
          defaultValue={state.accounts[0].id}
          options={state.accounts.map((acc) => ({
            value: acc.id,
            label: `····${acc.mask}`,
            description: acc.name,
          }))}
        />
      )}
      <ButtonWithLoader
        type='button'
        variant='outlined'
        isLoading={isLoadingBankAccount || isLoading}
        buttonComponent={SecondaryButton}
        onClick={() => open()}
      >
        {label}
      </ButtonWithLoader>
    </Flex>
  )
}

const PlaidLink: React.FC<PlaidLinkProps> = (props) => {
  const [linkToken, setLinkToken] = useState<string>()
  const apiClient = useAuthenticatedPaymentVerificationApiClient()
  const { plaidAccessToken: accessToken, countryCode } = props
  const isForUpdate = !!accessToken

  const fetchLinkToken = async () => {
    const res = await apiClient.createPlaidLinkToken({ payload: { countryCode, accessToken } })
    setLinkToken(res?.linkToken)
  }

  useEffect(() => {
    fetchLinkToken()
  }, [])

  if (!linkToken) {
    return <Spinner />
  }

  return <Content {...props} linkToken={linkToken} fetchLinkToken={fetchLinkToken} isForUpdate={isForUpdate} />
}

export { PlaidLink }
