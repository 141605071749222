import React from 'react'

import { FAILED_STATUS, IMMUTABLE_STATUS, Invoice, InvoiceStatus, SUCCESS_STATUS } from '@guiker/payment-shared'
import { PaginatedTable, PaginatedTableProps, Pagination } from '@guiker/react-framework'

import { useT } from '../../i18n'
import { getInvoiceColumns } from './invoice-columns'

const StatusMap = {
  paid: SUCCESS_STATUS,
  processing: IMMUTABLE_STATUS,
  bad: FAILED_STATUS,
  other: ['SENT', 'CANCELED', 'REFUNDED'] as InvoiceStatus[],
}

type InvoiceTableProps<StatusGroupName extends string = never, TabValue extends object = never> = Pick<
  PaginatedTableProps<Invoice, StatusGroupName, InvoiceStatus, TabValue>,
  'queryKey' | 'fetcher' | 'tabs'
> & {
  withSearch?: boolean
  withGroups?: boolean
  pagination?: Partial<Pagination>
}

export const InvoiceTable = <StatusGroupName extends string = never, TabValue extends object = never>({
  fetcher,
  pagination,
  queryKey,
  tabs,
  withGroups = true,
  withSearch = true,
}: InvoiceTableProps<StatusGroupName, TabValue>) => {
  const { tShared } = useT({ entity: 'invoice' })

  return (
    <PaginatedTable
      queryKey={queryKey}
      fetcher={fetcher}
      tabs={tabs}
      search={withSearch}
      pagination={pagination}
      statusGroups={
        withGroups
          ? {
              mapper: StatusMap,
              groups: [
                { name: 'all', label: tShared('statusGroup.all') },
                { name: 'paid', label: tShared('statusGroup.paid') },
                { name: 'processing', label: tShared('statusGroup.processing') },
                { name: 'bad', label: tShared('statusGroup.bad') },
                { name: 'other', label: tShared('statusGroup.other') },
              ],
            }
          : undefined
      }
      columns={getInvoiceColumns()}
    />
  )
}
