import { getBankByRouting } from '@guiker/money'

import { PayoutMethod } from '../entity'
import { typeChecker } from './type-checker'

const payoutMethodInfoBuilder = (payoutMethod: PayoutMethod) => ({
  getDisplay: (): string => {
    const last4digit = (val: string) => (!!val ? `···· ${val.slice(-4)}` : '')
    const findLabel = (routingNumber: string, accountNumber: string) => {
      const bank = getBankByRouting(routingNumber)
      const label = last4digit(accountNumber)
      return bank?.shortName ? `${bank.shortName} ${label}` : label
    }
    if (typeChecker.isEFT(payoutMethod)) {
      if (payoutMethod.details?.accountNumber)
        return findLabel(payoutMethod.details?.institutionNumber, payoutMethod.details?.accountNumber)
      else return '--- -- -----'
    } else if (typeChecker.isACH(payoutMethod)) {
      if (payoutMethod.details?.accountNumber)
        return findLabel(payoutMethod.details?.routingNumber, payoutMethod.details?.accountNumber)
      else return '--- -- -----'
    } else {
      return '--- -- -----'
    }
  },
})

export { payoutMethodInfoBuilder }
