import { Payment } from '@guiker/base-entity'

import { ParticipantStepStatus } from '.'
import { BookingPaymentTotal } from './booking-payment-total'
import { Contribution, ContributionStatus } from './contribution'

import ServiceFeeType = Payment.RentalServiceFeeType
type ServiceFee = Payment.RentalServiceFee & { legacyId?: number }
type BookingPaymentEligibility = Payment.PaymentEligibility

enum PaymentCondition {
  CHARGE_NOW = 'CHARGE_NOW',
  CHARGE_ON_MOVE_IN_DATE = 'CHARGE_ON_MOVE_IN_DATE',
}

type BookingPayment = {
  bookingId?: string
  condition: PaymentCondition
  total: BookingPaymentTotal
  serviceFee: ServiceFee
  contributions: Contribution[]
  eligibility?: BookingPaymentEligibility
  instalments: {
    amount: number
    chargeDate: string
  }[]
  /** @description transient. remove once rent payment service takes care of fees */
  serviceFees?: ServiceFee[]
}

type PaymentVerification = {
  userId: string
  userName: string
  status: ContributionStatus
  amount: number | null
  isWaived: boolean
  stepStatus: Exclude<ParticipantStepStatus, ParticipantStepStatus.NOT_READY>
}

export { BookingPayment, BookingPaymentEligibility, PaymentVerification, PaymentCondition, ServiceFee, ServiceFeeType }
