import React from 'react'

import { Flex, P } from '@guiker/react-framework'

export const InvoiceActivityLogEmptyState: React.FC = () => {
  return (
    <Flex fullWidth justifyContent='space-between'>
      <P>No invoice activity to display</P>
    </Flex>
  )
}
