import { CurrencyISO } from '@guiker/shared-framework'

import { ScopeType } from '../invoice'

type MaxInvoiceAmountKey = Exclude<ScopeType, ScopeType.LEGACY> | 'custom'
export const maxInvoiceAmount: {
  [key in MaxInvoiceAmountKey]: { [key in CurrencyISO]: number }
} = {
  fundingAccountDeposit: {
    [CurrencyISO.CAD]: 2000000,
    [CurrencyISO.USD]: 0,
  },
  buyStockOrder: {
    [CurrencyISO.CAD]: 2000000,
    [CurrencyISO.USD]: 0,
  },
  tenantInstalment: {
    [CurrencyISO.CAD]: 1000000,
    [CurrencyISO.USD]: 1000000,
  },
  custom: {
    [CurrencyISO.CAD]: 1000000,
    [CurrencyISO.USD]: 1000000,
  },
}

export const getOnlineTransactionThreshold = (args: { scopeType?: string; currency: CurrencyISO }) => {
  const scopeType = args.scopeType as ScopeType
  const currency = args.currency

  if (!scopeType || scopeType === ScopeType.LEGACY) {
    return maxInvoiceAmount.custom[currency]
  }

  return maxInvoiceAmount[scopeType]?.[currency] ?? 0
}
