import { buildInvoiceMonetaryDetails, getServiceFee, Invoice, PayInMethodType, Tax } from '@guiker/payment-shared'
import { AnyColor, useTranslation } from '@guiker/react-framework'
import { compact, MarkOptional, money } from '@guiker/shared-framework'

import { buildInvoiceItems, creditCardProcessingFee } from './build-custom-charge-invoice-items'

export type BreakdownItem = {
  color?: AnyColor
  label: string
  price: string
}

export const buildInvoiceBreakdown = ({
  invoice,
  taxes = [],
  payInMethodType,
}: {
  invoice: MarkOptional<Invoice, 'id' | 'customer'>
  taxes?: Tax[]
  payInMethodType: PayInMethodType
}): {
  totalAmount: number
  breakdownItems: BreakdownItem[]
} => {
  const { t } = useTranslation(['common-payment'])
  const isCustomChargeInvoice = !invoice?.scope

  const { currency, nonTaxableAmount, taxableAmount, taxInfo, totalAmount } = buildInvoiceMonetaryDetails({
    invoice,
    taxes,
    customServiceFee: getServiceFee(payInMethodType),
  })

  if (isCustomChargeInvoice) {
    const items = buildInvoiceItems({
      currency,
      totalAmount,
      nonTaxableAmount,
      taxInfo,
      taxableAmount,
      payinMethodType: payInMethodType,
    })

    return {
      totalAmount: totalAmount.final,
      breakdownItems: compact(items),
    }
  } else {
    const breakdownItems: BreakdownItem[] = invoice.items.map((item) => {
      const price = item.pricePerUnit * item.quantity

      return {
        label: item.label,
        price: money.fromAmount(price, currency).toString(true),
      }
    })

    if (totalAmount.tax) {
      breakdownItems.push({
        color: 30,
        label: t('common-payment:invoice.taxes', taxInfo),
        price: money.fromAmount(totalAmount.tax, currency).toString(true),
      })
    }

    if (payInMethodType === PayInMethodType.CREDIT_CARD) {
      const tPrefix = 'common-payment:invoice'
      breakdownItems.push(creditCardProcessingFee({ t, tPrefix, subTotalAmount: totalAmount.subTotal, currency }))
    }

    return {
      totalAmount: totalAmount.final,
      breakdownItems,
    }
  }
}
