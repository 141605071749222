import React, { useEffect } from 'react'

import { usePayInMethodContext } from '@guiker/payment-context'
import {
  ColumnGridItem,
  Flex,
  FullScreenSpinner,
  makeStyles,
  P,
  RadioGroup,
  useFormContext,
  useTranslation,
} from '@guiker/react-framework'

import { getPayInMethodLabels } from '../../utils'
import { MinimizedPayInMethodSelector } from '../MinimizedPayInMethodSelector'
import { PayInMethodIcon } from '../PayInMethodIcon'
import { AddPayInMethod } from './AddPayInMethod'
import { SelectedPayInMethod } from './SelectedPayInMethod'

const useStyles = makeStyles(
  {
    payInMethodOptionRadioInput: {
      alignSelf: 'center',
    },
  },
  { name: 'PayInMethodSelector' },
)

type Props = {
  condensed?: boolean
  invoiceAmount?: number
  name?: string
  showLabel?: boolean
}

export const FormContent: React.FC<Props> = ({ name: fieldName, invoiceAmount }) => {
  const { t } = useTranslation('common-payment')
  const classes = useStyles()
  const name = fieldName ?? 'payInMethod'

  const { region, setSelectedById, selected, payInMethods, isLoading } = usePayInMethodContext()
  const { setValue } = useFormContext()

  useEffect(() => {
    if (selected) {
      setValue(name, selected.id)
    }
  }, [selected])

  if (isLoading) return <FullScreenSpinner />

  return (
    <ColumnGridItem md={2}>
      <RadioGroup
        name={name}
        maxWidth='100%'
        defaultValue={selected?.id}
        adornmentPosition='start'
        onChange={(e) => setSelectedById(e.target.value)}
        options={
          payInMethods?.map((payInMethod) => {
            const { id, type, brand, brandName, routingNumber, lastDigits, secondaryLabel } = getPayInMethodLabels({
              payInMethod,
              region,
              t,
              amount: invoiceAmount,
            })

            return {
              value: id,
              label: (
                <P mb={0} fontWeight={500}>
                  {brandName}
                  <P component='span' color={60} mb={0} ml={1}>
                    {lastDigits}
                  </P>
                </P>
              ),
              className: classes.payInMethodOptionRadioInput,
              adornment: <PayInMethodIcon type={type} brand={brand} routingNumber={routingNumber} />,
              description: secondaryLabel,
            }
          }) || []
        }
      />
    </ColumnGridItem>
  )
}

export const PayInMethodSelector: React.FC<Props> = ({
  showLabel = true,
  invoiceAmount,
  name = 'payInMethod',
  condensed = false,
}) => {
  const { modalManager } = usePayInMethodContext()

  return (
    <Flex flexDirection='column' gap={2}>
      {condensed ? (
        <MinimizedPayInMethodSelector name={name} invoiceAmount={invoiceAmount} showLabel={showLabel} />
      ) : (
        <FormContent name={name} invoiceAmount={invoiceAmount} />
      )}
      <Flex flexDirection='column' gap={modalManager.buttonType.value === 'p' ? 1 : 2}>
        <AddPayInMethod />
        <SelectedPayInMethod />
      </Flex>
    </Flex>
  )
}
