import React from 'react'

import {
  AdminInvoiceContextProvider as BaseAdminInvoiceContextProvider,
  useAdminInvoiceContext,
} from '@guiker/invoice-context'
import { useAdminPaymentApiClient } from '@guiker/payment-context'
import { ScopeType } from '@guiker/payment-shared'
import { Outlet, useParams, useQuery } from '@guiker/react-framework'
import { Scope } from '@guiker/shared-framework'

import { InvoiceContextProvider } from './InvoiceContext'

export type AdminInvoiceContextProviderProps = React.PropsWithChildren

const AdminInvoiceContextProvider: React.FC<AdminInvoiceContextProviderProps> = ({ children }) => {
  const apiClient = useAdminPaymentApiClient()
  const { data: invoice, isFetching: isLoadingInvoice } = useAdminInvoiceContext()
  const { data: taxes, isLoading: isLoadingTaxes } = useQuery(['taxes'], () => apiClient.readAllTaxes())
  const { data: events, isLoading: isLoadingEvents } = useQuery(
    ['events', invoice?.id, invoice?.transactions?.length],
    () => apiClient.readAllEventByInvoiceId({ pathParams: { invoiceId: invoice?.id } }),
    {
      enabled: !!invoice?.id,
    },
  )

  return (
    <InvoiceContextProvider
      taxes={taxes}
      invoice={invoice}
      events={events || []}
      isLoading={isLoadingTaxes || isLoadingInvoice || isLoadingEvents}
      isAdmin={true}
    >
      {children}
    </InvoiceContextProvider>
  )
}

export const AdminInvoiceNestedRouter: React.FC<{ invoiceId: string }> = () => {
  const { invoiceId } = useParams()

  return (
    <BaseAdminInvoiceContextProvider invoiceId={invoiceId}>
      <AdminInvoiceContextProvider>
        <Outlet />
      </AdminInvoiceContextProvider>
    </BaseAdminInvoiceContextProvider>
  )
}

export const AdminInvoiceScopeNestedRouter: React.FC<{ scope: Scope<ScopeType> }> = ({ scope }) => {
  return (
    <BaseAdminInvoiceContextProvider scope={scope}>
      <AdminInvoiceContextProvider>
        <Outlet />
      </AdminInvoiceContextProvider>
    </BaseAdminInvoiceContextProvider>
  )
}
