import { generateEventsDefinition } from '@guiker/event'

import { NEWPropertyOwnership } from '../../entity'
import { baseEvent } from '../base'

const basePropertyOwnershipEvent = {
  ...baseEvent,
  entity: 'PROPERTY_OWNERSHIP',
  data: null as unknown as NEWPropertyOwnership,
}

export const PropertyOwnershipEventsDefinition = generateEventsDefinition({
  ...basePropertyOwnershipEvent,
  eventTypes: {
    REPRESENTATIVES_UPDATED: 'REPRESENTATIVES_UPDATED',
    FINANCIAL_INFO_UPDATED: 'FINANCIAL_INFO_UPDATED',
    HOLDING_PERIOD_UPDATED: 'HOLDING_PERIOD_UPDATED',
    UPDATE_TO_REAL_OWNER: 'UPDATE_TO_REAL_OWNER',
    DEACTIVATED: 'DEACTIVATED',
  } as const,
})

export const Events = PropertyOwnershipEventsDefinition.events
export const EventTypes = PropertyOwnershipEventsDefinition.eventTypes
export type PropertyOwnershipEventTypes = typeof EventTypes
