import { useContext } from 'react'

import { InvoiceContext } from '../context'

export const useInvoiceContext = () => {
  const context = useContext(InvoiceContext)

  if (context === undefined) {
    throw new Error('useInvoiceContext can only be used inside InvoiceContext')
  }

  return context
}
