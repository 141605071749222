import React from 'react'

import { Invoice, isInvoiceFailed, isInvoicePayable } from '@guiker/payment-shared'
import { Chip, ChipProps, useDateFormatter } from '@guiker/react-framework'

import { getInvoiceColor, useGetInvoiceStatus } from '../../../utils/invoice-utils'

type InvoiceDetailStatusChipProps = Pick<ChipProps, 'size' | 'variant'> & {
  invoice: Pick<Invoice, 'dueBy' | 'createdAt' | 'status' | 'updatedAt' | 'transactions'>
  withDate?: boolean
}

export const InvoiceDetailStatusChip: React.FC<InvoiceDetailStatusChipProps> = ({
  invoice,
  size = 'medium',
  variant = 'filled',
  withDate,
}) => {
  const statusLabel = useGetInvoiceStatus()(invoice)
  const { formatDate } = useDateFormatter()
  const isPast = new Date(invoice.dueBy || invoice.createdAt) < new Date()
  const isOverdue = !isInvoiceFailed(invoice) && isInvoicePayable(invoice) && isPast
  const date = formatDate(invoice.transactions[invoice.transactions.length - 1]?.createdAt || invoice.updatedAt)

  return (
    <Chip variant={variant} size={size} color={getInvoiceColor(invoice)}>
      {withDate && !isOverdue ? `${statusLabel} | ${date}` : statusLabel}
    </Chip>
  )
}
