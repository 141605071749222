import { capitalize, compact } from 'lodash'
import { DateTime } from 'luxon'

import { EligibleDate, parseDate } from './parse-date'

export const _baseDateFormatter = ({ locale } = { locale: 'en' }) => {
  const localizeDate = (dateTime: DateTime) => {
    return dateTime.setLocale(locale)
  }

  const baseFormatDate = (
    date: EligibleDate,
    options?: {
      format?: string
      alwaysDisplayYear?: boolean
      ignoreTimezone?: boolean
      withTime?: boolean
      withRelativeDate?: boolean
    },
  ) => {
    if (!date) {
      return
    }

    const dateTime = parseDate(date, options?.ignoreTimezone)

    const shouldDisplayYear = dateTime.year !== DateTime.fromJSDate(new Date()).year || options?.alwaysDisplayYear
    const diffDays = dateTime.diffNow('days').days

    if (options?.format) {
      return dateTime.toFormat(options.format)
    }

    if (options?.withRelativeDate && diffDays > -4 && diffDays < 2) {
      return compact([
        capitalize(localizeDate(dateTime).toRelativeCalendar()),
        options?.withTime && dateTime.toFormat('HH:mm'),
      ]).join(', ')
    } else if (options?.withTime) {
      if (shouldDisplayYear) {
        return dateTime.toFormat('MMM. dd yyyy, HH:mm')
      } else {
        return dateTime.toFormat('MMM. dd, HH:mm')
      }
    } else {
      if (shouldDisplayYear) {
        return dateTime.toFormat('MMM. dd yyyy')
      } else {
        return dateTime.toFormat('MMM. dd')
      }
    }
  }

  const formatDate = (
    date: EligibleDate,
    {
      alwaysDisplayYear,
    }: {
      alwaysDisplayYear?: boolean
    } = {},
  ) => {
    return baseFormatDate(date, {
      alwaysDisplayYear: alwaysDisplayYear ?? true,
      withRelativeDate: false,
      withTime: false,
      ignoreTimezone: true,
    })
  }

  const formatDateTime = (
    date: EligibleDate,
    {
      alwaysDisplayYear,
      ignoreTimezone,
    }: {
      alwaysDisplayYear?: boolean
      ignoreTimezone?: boolean
    } = {},
  ) => {
    return baseFormatDate(date, {
      alwaysDisplayYear: alwaysDisplayYear ?? true,
      ignoreTimezone,
      withRelativeDate: false,
      withTime: true,
    })
  }

  const formatWithRelativeDate = (date: EligibleDate) => {
    return baseFormatDate(date, {
      alwaysDisplayYear: true,
      ignoreTimezone: false,
      withRelativeDate: true,
      withTime: false,
    })
  }

  const formatWithRelativeDateTime = (date: EligibleDate) => {
    return baseFormatDate(date, {
      alwaysDisplayYear: true,
      ignoreTimezone: false,
      withRelativeDate: true,
      withTime: true,
    })
  }

  const formatMonthAndYear = (date: EligibleDate) => {
    return baseFormatDate(date, { format: 'LLL yyyy', ignoreTimezone: true })
  }

  const formatDayAndMonth = (date: EligibleDate) => {
    return baseFormatDate(date, { format: 'dd LLL', ignoreTimezone: true })
  }

  return {
    formatDate,
    formatDateTime,
    formatMonthAndYear,
    formatWithRelativeDate,
    formatWithRelativeDateTime,
    formatDayAndMonth,
  }
}

export const {
  formatDate,
  formatDateTime,
  formatMonthAndYear,
  formatWithRelativeDate,
  formatWithRelativeDateTime,
  formatDayAndMonth,
} = _baseDateFormatter({ locale: 'en' })
