import { buildQueueDefinition } from '@guiker/queue'

import { config } from '../config'
import { OccupancyLease } from '../entity'

type MergeListingPayload = {
  listingId: number
  street_number: string
  city: string
  street_name: string
  zipcode: string
  country: string
  state: string
  city_name: string
  isDashed: boolean
}

export const Queue = {
  //deprecate after legacy listing migration
  MigrateListingByOwnerMerge: buildQueueDefinition({
    name: 'MigrateListingByOwnerMerge',
    serviceName: config.apiName,
    payload: null as (MergeListingPayload & { users: string })[],
    isFifo: true,
    batchSize: 1,
  }),
  MigrateListingBuildings: buildQueueDefinition({
    name: 'MigrateListingBuildings',
    serviceName: config.apiName,
    payload: null as MergeListingPayload[],
    isFifo: true,
    batchSize: 1,
  }),
  //
  UpdatePropertyOccupancy: buildQueueDefinition({
    name: 'UpdatePropertyOccupancy',
    serviceName: config.apiName,
    payload: null as {
      voided: boolean
      propertyId: string
      unit: string
      lease: OccupancyLease
    }[],
    isFifo: true,
    batchSize: 1,
  }),
} as const
