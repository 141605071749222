import React from 'react'

import { Invoice } from '@guiker/payment-shared'
import { ActivityMarker } from '@guiker/react-framework'
import { Event } from '@guiker/shared-framework'

export const transformEventsToActivityItems = ({
  events,
  transformToComponentMethod,
}: {
  events: Event[]
  transformToComponentMethod?: ({ event }: { event: Event<Invoice> }) => {
    content: any
    label: any
  }
}) => {
  const filteredEvents = events
    ?.map((event) => transformToComponentMethod({ event: event as Event<Invoice> }))
    ?.filter((item) => !!item)

  return filteredEvents?.map((item, index) => ({
    ...item,
    marker: {
      isFirst: index === 0,
      isLast: index === filteredEvents.length - 1,
      icon: <ActivityMarker />,
    },
  }))
}
