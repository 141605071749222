import React from 'react'

import { usePayoutMethodNestedContext } from '@guiker/payout-context'
import { ActivityTrackerItem, LinkProps } from '@guiker/react-framework'

import { useInvoiceContext } from '../../hooks'
import { ViewInvoiceDetail } from '../ViewInvoiceDetail'
import { AdminAccessLink } from './utils'

type ReceiverContentProps = {
  customerLinkProps?: LinkProps
  topActions?: React.ReactNode
  activityLogItems: ActivityTrackerItem[]
  adminAccessLinks?: AdminAccessLink
  payoutMethodLinkProps?: LinkProps & { value: string }
}

export const ReceiverContent: React.FC<ReceiverContentProps> = ({
  customerLinkProps,
  topActions,
  activityLogItems,
  adminAccessLinks,
  payoutMethodLinkProps,
}) => {
  const { invoice, lastTransaction, breakdownItems, totalAmount } = useInvoiceContext()
  const { payoutMethod } = usePayoutMethodNestedContext({ shouldThrowIfUndefined: false }) ?? {}
  return (
    <ViewInvoiceDetail
      invoice={invoice}
      breakdownItems={breakdownItems}
      totalAmount={totalAmount}
      payInMethod={lastTransaction?.payInMethod}
      customerLinkProps={customerLinkProps}
      adminAccessLinks={adminAccessLinks}
      payoutMethod={payoutMethod}
      payoutMethodLinkProps={payoutMethodLinkProps}
      topActions={topActions}
      activityLogItems={activityLogItems}
      isHistoryOpen
    />
  )
}
