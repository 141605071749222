import { Payment } from '@guiker/base-entity'
import { generateEventsFromEnum } from '@guiker/event'

export const BasePayInMethodEvent = {
  context: 'PAYMENT',
  data: null as unknown as Payment.PayInMethod,
} as const

export enum PayInMethodEventTypes {
  CREATED = 'CREATED',
  DELETED = 'DELETED',
  VERIFICATION_TOKEN_REFRESHED = 'VERIFICATION_TOKEN_REFRESHED',
}

export const PayInMethodLegacyEvents = generateEventsFromEnum({
  ...BasePayInMethodEvent,
  data: {
    payInMethodId: null as unknown as number,
  },
  entity: 'LEGACY_PAY_IN_METHOD',
})(PayInMethodEventTypes)

export const PayInMethodEvents = generateEventsFromEnum({
  ...BasePayInMethodEvent,
  entity: 'PAY_IN_METHOD',
})(PayInMethodEventTypes)
