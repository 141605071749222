import { buildWebAppRoutes } from '@guiker/shared-framework'

import { config } from '../config'

export const { routes: officeRoutes, pathBuilder: officePathBuilder } = buildWebAppRoutes(
  {
    root: {
      path: '/',
      routes: {
        fundingAccount: {
          path: '/funding-accounts',
          routes: {
            deposits: '/deposits',
            withdrawals: '/withdrawals',
          },
        },
        invoices: {
          path: '/invoices',
          routes: {
            list: '/',
            batchCharges: {
              path: '/batch-charges',
              routes: {
                list: '/',
                bulkInvoiceCreate: '/bulk-create',
                byBatchChargeId: {
                  path: '/:batchChargeId',
                  routes: {
                    batchCharges: '/invoices',
                  },
                },
              },
            },
            create: '/create',
            byInvoiceId: {
              path: '/:invoiceId',
              routes: {
                viewInvoiceScreen: '/',
              },
            },
          },
        },
      },
    },
  } as const,
  config.domainName,
)
