import React from 'react'

import { Invoice, invoiceTypeChecker } from '@guiker/payment-shared'
import { Flex, P, PSmall, PSmaller, useTranslation } from '@guiker/react-framework'
import { currency, money, optionalConcat } from '@guiker/shared-framework'

import { getInvoiceReason } from '../../utils/get-invoice-reason'

type InvoiceReasonProps = {
  invoice: Invoice
  size?: 'mediun' | 'small'
}

const InvoiceReason: React.FC<InvoiceReasonProps> = ({ invoice, size = 'medium' }) => {
  const { t } = useTranslation(['main-propsharing', 'main-payment'])
  const tStockOrderPrefix = 'main-propsharing:screens.stockOrderCheckout.sections.orderDetails'
  const MainComponent = size === 'medium' ? P : PSmall
  const SecondaryComponent = size === 'medium' ? PSmall : PSmaller
  const reason = getInvoiceReason({ invoice })

  if (invoiceTypeChecker.scope.isTenantInstalment(invoice)) {
    return (
      <Flex flexDirection='column'>
        <MainComponent mb={0}>{reason}</MainComponent>
        <SecondaryComponent mb={0} color={60}>
          {invoice.info?.description ?? invoice.metadata.description}
        </SecondaryComponent>
      </Flex>
    )
  } else if (invoiceTypeChecker.scope.isBuyStockOrder(invoice)) {
    const item = invoice.items[0]

    return (
      <Flex flexDirection='column'>
        <MainComponent mb={0} component='span'>
          {reason}
          <MainComponent mb={0} ml={0.5} component='span' color={60}>{`(x ${item.quantity})`}</MainComponent>
        </MainComponent>
        <SecondaryComponent color={60} mb={0}>
          {t(`${tStockOrderPrefix}.pricePerUnit`, {
            price: money.fromAmount(item.pricePerUnit, currency[invoice.currency]).toString(),
          })}
        </SecondaryComponent>
      </Flex>
    )
  } else if (invoiceTypeChecker.scope.isFundingAccountDeposit(invoice)) {
    return (
      <Flex>
        <MainComponent mb={0}>{reason}</MainComponent>
      </Flex>
    )
  } else if (invoiceTypeChecker.scope.isNone(invoice)) {
    const { listingId, unit } = { ...invoice.metadata }
    return (
      <Flex flexDirection='column'>
        <MainComponent mb={0}>{reason}</MainComponent>
        {listingId || unit ? (
          <SecondaryComponent mb={0} color={60}>
            {optionalConcat([listingId, unit], ' / ')}
          </SecondaryComponent>
        ) : (
          <></>
        )}
      </Flex>
    )
  } else {
    ;<div></div>
  }
}

export { InvoiceReason }
