import { generateEventsDefinition, ValueOfEventTypes } from '@guiker/event'

import { Invoice, InvoiceStatus, PayInMethodType, Transaction } from '../../entity'
import { baseEvent } from '../base'

export type TransactionEventData = {
  id: string // invoiceId
  status: InvoiceStatus
  transactions: Transaction[]
}

export type PayInMethodPayload = {
  id: string
  type: PayInMethodType
}

export const definition = generateEventsDefinition({
  ...baseEvent,
  entity: 'INVOICE',
  data: null as Invoice,
  eventTypes: {
    CREATED: 'CREATED',
    CANCELLED: 'CANCELLED',
    CHARGE_PENDING: {
      type: 'CHARGE_PENDING',
      eventData: null as TransactionEventData,
    },
    CHARGE_SUCCEED: {
      type: 'CHARGE_SUCCEED',
      eventData: null as TransactionEventData,
    },
    CHARGE_FAILED: 'CHARGE_FAILED',
    OFFLINE_CHARGE_PENDING: {
      type: 'OFFLINE_CHARGE_PENDING',
      eventData: null as TransactionEventData,
    },
    OFFLINE_CHARGE_SUCCEED: {
      type: 'OFFLINE_CHARGE_SUCCEED',
      eventData: null as TransactionEventData,
    },
    OFFLINE_CHARGE_FAILED: {
      type: 'OFFLINE_CHARGE_FAILED',
      eventData: null as TransactionEventData,
    },
    OFFLINE_CHARGE_UNDONE: 'OFFLINE_CHARGE_UNDONE',
    FUNDING_ACCOUNT_CHARGE_PENDING: {
      type: 'FUNDING_ACCOUNT_CHARGE_PENDING',
      eventData: null as TransactionEventData,
    },
    FUNDING_ACCOUNT_CHARGE_SUCCEED: {
      type: 'FUNDING_ACCOUNT_CHARGE_SUCCEED',
      eventData: null as TransactionEventData,
    },
    FUNDING_ACCOUNT_CHARGE_FAILED: {
      type: 'FUNDING_ACCOUNT_CHARGE_FAILED',
      eventData: null as TransactionEventData,
    },
    NO_AMOUNT_CHARGED: {
      type: 'NO_AMOUNT_CHARGED',
      eventData: null as TransactionEventData,
    },
    STOCK_ORDER_INVOICE_REIMBURSED: {
      type: 'STOCK_ORDER_INVOICE_REIMBURSED',
      eventData: null as TransactionEventData,
    },
    REFUND_PENDING: 'REFUND_PENDING',
    REFUND_FAILED: 'REFUND_FAILED',
    REFUND_SUCCEED: 'REFUND_SUCCEED',
    IMMEDIATE_CHARGEABLE_CREATED: {
      type: 'IMMEDIATE_CHARGEABLE_CREATED',
      eventData: null as { payInMethod: PayInMethodPayload },
    },
    VERIFICATION_REQUESTED: {
      type: 'VERIFICATION_REQUESTED',
      eventData: null as { payInMethod: PayInMethodPayload },
    },
    VERIFICATION_FAILED: 'VERIFICATION_FAILED',
    VERIFICATION_SUCCEED: 'VERIFICATION_SUCCEED',
    SETTLED: 'SETTLED',
  } as const,
})

export const { events: Events, eventTypes: EventTypes } = definition
export type EventTypes = ValueOfEventTypes<typeof definition>
