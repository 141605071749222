import React from 'react'

import { computeInvoiceTotalAmount, Invoice } from '@guiker/payment-shared'
import { ColumnConfig, Flex, Link, P, PSmall, RouterLink, TextButton, useDateFormatter } from '@guiker/react-framework'
import { currency, money } from '@guiker/shared-framework'

import { useT } from '../../i18n'
import { InvoiceReason } from '../InvoiceReason'
import { InvoiceDetailStatusChip } from '../ViewInvoiceDetail'

export const getInvoiceColumns = (): ColumnConfig<Invoice>[] => {
  const { tBase, tOffice, tShared } = useT({ screenName: 'listInvoices' })
  const { formatDate, formatDateTime } = useDateFormatter()

  return [
    {
      label: tOffice('customer'),
      name: 'customer',
      size: 1.5,
      verticalAlign: 'top',
      renderValue: ({ customer }) => (
        <Link to={`/user/${customer.id}`} prefixWithLocale={false}>
          <Flex flexDirection='column'>
            <P mb={0}>{[customer.firstName, customer.lastName].join(' ')}</P>
            <PSmall color={60} mb={0}>
              {customer.emailAddress}
            </PSmall>
          </Flex>
        </Link>
      ),
    },

    {
      label: tShared('reason'),
      size: 1.5,
      name: 'reason',
      verticalAlign: 'top',
      renderValue: (invoice) => <InvoiceReason invoice={invoice} />,
    },
    {
      label: tOffice('status'),
      name: 'status',
      size: 1,
      verticalAlign: 'top',
      renderValue: (invoice) => {
        return <InvoiceDetailStatusChip invoice={invoice} size='small' />
      },
    },
    {
      label: tShared('dueBy'),
      name: 'dateCharged',
      size: 0.7,
      verticalAlign: 'top',
      renderValue: ({ dueBy, createdAt }) => formatDate(dueBy || createdAt),
    },
    {
      label: tShared('lastUpdate'),
      name: 'updatedAt',
      size: 0.7,
      verticalAlign: 'top',
      renderValue: (invoice) => {
        const lastUpdatedAt = invoice.transactions[invoice.transactions.length - 1]?.createdAt
        return lastUpdatedAt && formatDateTime(lastUpdatedAt)
      },
    },
    {
      label: tShared('amount'),
      headerOptions: {
        textAlign: 'right',
      },
      name: 'amount',
      size: 1,
      verticalAlign: 'top',
      renderValue: (invoice) => (
        <Flex flexDirection='column' alignItems='flex-end'>
          {money.fromAmount(computeInvoiceTotalAmount(invoice), currency[invoice.currency]).toString(true)}
        </Flex>
      ),
    },
    {
      name: 'actions',
      headerOptions: {
        textAlign: 'center',
      },
      size: 0.7,
      verticalAlign: 'top',
      renderValue: (invoice) => {
        return (
          <Flex flexDirection='column' alignItems='center'>
            <RouterLink to={`/payment/invoices/${invoice.id}`} prefixWithLocale={false}>
              <TextButton>{tBase('actions.view')}</TextButton>
            </RouterLink>
          </Flex>
        )
      },
    },
  ]
}
