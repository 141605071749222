import { last } from '@guiker/shared-framework'

import { Invoice, InvoiceStatus, PayInMethodType, Scopeless, ScopeType } from '../entity'

export const FAILED_STATUS: InvoiceStatus[] = ['PAYMENT_FAILED', 'REFUND_FAILED', 'VERIFICATION_FAILED']
export const PAYABLE_STATUS: InvoiceStatus[] = ['SENT', 'PAYMENT_FAILED', 'VERIFICATION_FAILED']
export const PROCESSING_STATUS: InvoiceStatus[] = [
  'PENDING',
  'VERIFICATION_REQUESTED',
  'VERIFICATION_SUCCEED',
  'REFUND_PENDING',
]
export const SUCCESS_STATUS: InvoiceStatus[] = ['PAID', 'SETTLED']
export const CANCELABLE_STATUS: InvoiceStatus[] = [...PAYABLE_STATUS]
export const IMMUTABLE_STATUS: InvoiceStatus[] = [...PROCESSING_STATUS, 'CANCELED', 'REFUND_PENDING']

export const isInvoicePayable = (invoice: Pick<Invoice, 'status'>) => {
  return PAYABLE_STATUS.includes(invoice?.status)
}

export const isInvoiceRefundable = (invoice: Pick<Invoice, 'status' | 'transactions'>) => {
  const lastTransaction = last(invoice.transactions)
  const isRefundablePaidMethod = lastTransaction?.payInMethod?.type !== PayInMethodType.DIRECT_DEBIT_EFT
  return (
    !IMMUTABLE_STATUS.includes(invoice?.status) && isRefundablePaidMethod && SUCCESS_STATUS.includes(invoice?.status)
  )
}

export const isInvoiceCancelable = (invoice: Pick<Invoice, 'status'>) => {
  return CANCELABLE_STATUS.includes(invoice?.status)
}

export const isInvoiceFailed = (invoice: Pick<Invoice, 'status'>) => {
  return FAILED_STATUS.includes(invoice?.status)
}

export const isInvoiceSuccess = (invoice: Pick<Invoice, 'status'>) => {
  return SUCCESS_STATUS.includes(invoice?.status)
}

export const isInvoiceProcessing = (invoice: Pick<Invoice, 'status'>) => {
  return PROCESSING_STATUS.includes(invoice?.status)
}

export const isInvoiceImmutable = (invoice: Pick<Invoice, 'status'>) => {
  return IMMUTABLE_STATUS.includes(invoice?.status)
}

export const invoiceTypeChecker = {
  scope: {
    isTenantInstalment: (invoice: Invoice): invoice is Invoice<ScopeType.TENANT_INSTALMENT> => {
      return invoice.scope?.type === ScopeType.TENANT_INSTALMENT
    },
    isBuyStockOrder: (invoice: Invoice): invoice is Invoice<ScopeType.BUY_STOCK_ORDER> => {
      return invoice.scope?.type === ScopeType.BUY_STOCK_ORDER
    },
    isFundingAccountDeposit: (invoice: Invoice): invoice is Invoice<ScopeType.FUNDING_ACCOUNT_DEPOSIT> => {
      return invoice.scope?.type === ScopeType.FUNDING_ACCOUNT_DEPOSIT
    },
    isNone: (invoice: Invoice): invoice is Invoice<Scopeless> => {
      return !invoice.scope
    },
  },
}
